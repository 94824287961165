import React from "react";

interface Props {
    title: string
    html: string
}

interface State {
    open: boolean

}

export class QandAsegment extends React.Component<Props, State> {

    feedbackUrl: string

    constructor(props: Props) {
        super(props)
        this.state = { open: false }
        this.feedbackUrl = "https://docs.google.com/forms/d/e/1FAIpQLSchqatNxfAAL6FphPEjMGa1melU7SrX7nJ9qcdsZr0TnzZw_g/viewform?entry.433914081=" + encodeURIComponent(props.title)
    }

    onHeaderClicked = () => {
        this.setState({ open: !this.state.open })
    }
   
    render() {
        const { open } = this.state
        return (
            <div>
                {/* <Link key={faq.node.id} to={faq.node.frontmatter.path}> </Link> */}
                <h2 onClick={this.onHeaderClicked}
                    style={{ fontSize: "1.5em", cursor: "pointer" }}
                >
                    <i className={`fa fa-chevron-${open ? 'down' : 'right'}`} style={{ marginRight: "0.5em" }} />
                    {' '}
                    {this.props.title}
                </h2>
                { open &&
                    <div style={{ marginLeft: "2em" }}>
                        <div dangerouslySetInnerHTML={{ __html: this.props.html }} />
                        <div style={{ marginTop: "2em" }}>
                            <a href={this.feedbackUrl} className="feedbackLink">
                                Send us feedback on this answer
                            </a>
                        </div>
                    </div>
                }
                <div onClick={open ? undefined : this.onHeaderClicked}>
                    <br />
                    <br />
                </div>
            </div>
        )
    }
}