import React from 'react';

import './feedback.css'
import { LargeButton } from './LargeButton';


export function FeedbackButton() {
    return (
        <LargeButton
            href="https://docs.google.com/forms/d/e/1FAIpQLSfIYeydxDG0FnjHlLLEgzKY_2VoXDA_VFRYapTjNcuPVqLhWg/viewform"
            className="feedbackContainer"
            line1="Ask Questions"
            line2="Send Feedback"
        />
    )
}