import * as React from "react"

export function Licence() {
    return (
        <p style={{ marginLeft: "2em"}}>
            <a rel="license"
                href="http://creativecommons.org/publicdomain/zero/1.0/">
                <img src="http://i.creativecommons.org/p/zero/1.0/88x31.png" style={{ borderStyle: 'none'}} alt="CC0" />
            </a>
            <br />
            To the extent possible under law, the person who associated CC0
            with this work has waived all copyright and related or neighboring
                rights to this work.
            </p>
    )
}