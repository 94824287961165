import * as React from "react"
import { graphql } from "gatsby"

import '../assets/all.css'
import '../../static/font-awesome-4.7.0/css/font-awesome.css'

import mainImage from "../assets/1024px-Port_Willunga_Beach.jpg"
import { SectionTitle } from "../components/SectionTitle";
import { QandAsegment } from "../components/QandAsegment";
import { Head } from "../components/Head";
import { FeedbackButton } from "../components/Feedback";
import { Licence } from "../components/Licence"

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        siteName
      }
    }
    FAQs: allMarkdownRemark (filter: {fileAbsolutePath: {regex: "/FAQ/"}}) {
      edges {
        node {
          id
          html
          fileAbsolutePath
          frontmatter {
            title
            path
            # lang
          }
        }
      }
    }
  }
`
interface IndexPageProps {
  pageContext: {
    lang: string
  },
  data: {
    site: {
      siteMetadata: {
        siteName: string
      }
    }
    FAQs: {
      edges: {
        node: {
          id: string
          html: string
          frontmatter: {
            title: string
            path: string
            lang?: string
          }
        }
      }[]
    }
  }
}



export default class IndexPage extends React.Component<IndexPageProps, {}> {
  public render() {
    const { siteName } = this.props.data.site.siteMetadata

    const lang = "en"
    const titleText = "Climate Change"
    const subHeaderText = "Frequently asked questions" 

    const faqs =
      this.props.data.FAQs.edges
        .filter(a => (a.node.frontmatter.lang || "en") === lang)

    return (
      <main role="main" className="main">
        <Head />
        <FeedbackButton />

        <div className="hero-image__wrapper">

          <div className="hero-image"
            style={{ backgroundImage: `url(${mainImage})`, backgroundPositionY: '25%' }}
            title="Port Willunga beaches overlooking the cliffs and shore by Bolex17. Published at Wikimedia Commons. Used under Creative Commons Attribution 3.0 license with thanks."
          >
            <div className="hero-image__heading">
              <div className="container">
                <div className="hero-image-title__overflow">
                  <div className="hero-image-title__wrapper">
                    <h1 className="hero-image-title">{titleText}</h1>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="hero-sub-section">
          <div className="container">
            <div className="hero-sub-section-content">
              <p>{subHeaderText}</p>
            </div>
          </div>
        </div>

        <a id="main-content"></a>

        <div className="layout-content page-main">
          <article role="article" className="node node--type-person node--view-mode-full">

            <div className="node__content">
              <div className="container">

                {/* <SectionTitle title="" /> */}
                <div style={{ fontSize: "1.5em" }}>
                  {faqs
                    .sort((a, b) => a.node.frontmatter.path.localeCompare(b.node.frontmatter.path))
                    .map(faq => (
                      <QandAsegment
                        key={faq.node.id}
                        title={faq.node.frontmatter.title}
                        html={faq.node.html}
                      />
                    ))}
                </div>


              </div>

            </div>

          </article>
        </div>

        <Licence />

      </main>
    )
  }
}