import React from 'react';

import './largeButton.css'

interface Props {
    className?: string
    href: string
    line1: string
    line2?:string
}

export function LargeButton(props: Props) {
    return (
        <div className={`largeButton ${props.className || ''}`}>
            <a target="_blank" href={props.href}>
                {props.line1}
                {props.line2 && <br />}
                {props.line2}
            </a>
        </div>
    )
}