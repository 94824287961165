import React from "react";
import Helmet from "react-helmet";


import favicon from "../../static/favicon.png"

export function Head() {
    return (
        <Helmet
            title={"Climate Change Q & A"}
            meta={[
                { name: 'description', content: 'Climate Change Questions and Answers' },
                { name: 'keywords', content: 'climate,greenhouse,warming,environment,politics,auspol' },
            ]}
            link={[
                { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
            ]}
        />
    )
}